import React from 'react';
import pcGif from '../assets/pcgif.gif';

const About = () => {
  return (
    <div className="p-6 bg-transparent rounded-lg shadow-lg">
      <section id="about" className="flex flex-col md:flex-row items-center pt-6 pb-0">
        <div className="flex-1 flex items-center justify-center md:w-1/2 mt-4 md:mt-0">
          <img 
            src={pcGif} 
            alt="GIF del PC" 
            className="w-auto h-auto"
          />
        </div>
        <div className="flex-1 flex flex-col md:w-1/2">
          <div>
            <h2 className="text-3xl font-bold text-pastel-yellow mb-4">
              Sobre Mí
            </h2>
          </div>
          <div className="mt-6">
            <p className="text-lg text-gray-200">
            ¡Hola 👋🏼! 
            Mi nombre es Daniel Medina Cubero, soy desarrollador de software multiplataforma, con enfoque principalmente al Full-Stack, 
            también cuento con el título de técnico informático y varios cursos de especialización. 
            Llevo desarrollando software de manera independiente cerca de 3 años, 
            inicialmente empecé haciendo pequeños programas en Java, 
            actualmente me estoy enfocando a la Inteligencia Artificial y Análisis de Datos, 
            también desarrollo Webs/Aplicaciones con APIs, Flask, Retrofit, etc.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
